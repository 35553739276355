export default {
	data() {
		return {
			loading: true,

			getEquManage: {
				page: 1,
				size: 10,
				status: null,
				type: null,
				manufacturerDates: [],
				endManufacturerDate: null,
				startManufacturerDate: null,
				serialNumber:null,
			},
			tableData: [],
			total: 0,
			limitUpload: 1,
			fileTemp: null,
			showUpload: false,
		}
	},
	created() {
		this.getEquManageList();
	},
	/* beforeDestroy(){
		this.tableData.splice(0, this.tableData.length);
	}, */
	methods: {
		//解绑
		doUnlink(row) {
			console.log(row)
			this.$confirm(`您确定解绑发射器${row.serialNumber}么?`, "提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				lockScroll: false,
				type: "warning"
			}).then(() => {
				this.$axios.get('/api/web/patientDevice/unbind/' + row.uuid).then(res => {
					this.$message.success({
						message: res.data.message,
					});
					this.$router.push({path: 'equ-list'});
					this.getEquManageList();
				}).catch(error => {
					console.log(error);
					//this.$message.error("请求失败");
				});
			}).catch(() => {});
		},
		handleChange(file) {
			this.showUpload = true;
			this.fileTemp = file.raw;
		},
		cancelUpload() {
			this.showUpload = false;
			this.fileTemp = null;
		},
		confirmUpload() {
			if (this.fileTemp) {
				this.showUpload = false;
				if (this.fileTemp.type == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || this
					.fileTemp.type == 'application/vnd.ms-excel') {
					let fileName = this.fileTemp.name;
					let file = this.fileTemp;
					const formData = new FormData();
					formData.append('file', file);

					this.$axios({
						method: "post",
						url: "/api/file/cgmDevice/uploadFile?catalogue=cgmUploadCatalogue&fileName=" +
							fileName, // 接口地址
						// 请求头，发送FormData格式的数据，必须是 这种请求头。
						headers: {
							"Content-Type": "application/form-data"
						},
						data: formData
					}).then(res => {
						if (res.data.code === 200) {
							this.$message({
								message: "操作成功",
								type: "success",
							});
							this.getEquManageList();
						} else {
							this.$message({
								message: "操作失败",
								type: "error",
							});
						}
					}).catch(error => {
						console.log(error);
						//this.$message.error("请求失败");
					});

				} else {
					this.$message({
						type: 'warning',
						message: '附件格式错误，请重新上传！'
					})
				}
			} else {
				this.$message({
					type: 'warning',
					message: '请上传附件！'
				})
			}
		},
		// 监听 pagesize 改变的事件
		handleSizeChange(newSize) {
			this.getEquManage.size = newSize;
			this.getEquManageList()
		},
		//监听 页码值 改变的事件
		handleCurrentChange(newPage) {
			this.getEquManage.page = newPage;
			this.getEquManageList()
		},
		doGetEquManageSearch() {
			this.tableData.splice(0, this.tableData.length);
			console.log(this.getEquManage.manufacturerDates)
			this.getEquManage.page = 1;
			if (this.getEquManage.manufacturerDates !== null && this.getEquManage.manufacturerDates.length >
				1) {
				this.getEquManage.startManufacturerDate = this.getEquManage.manufacturerDates[0] + ' 00:00:00';
				this.getEquManage.endManufacturerDate = this.getEquManage.manufacturerDates[1] + ' 23:59:59';
			} else {
				this.getEquManage.startManufacturerDate = '';
				this.getEquManage.endManufacturerDate = '';
			}
			console.log(this.getEquManage)
			this.getEquManageList();
		},
		/*获取患者列表数据*/
		getEquManageList() {
			this.tableData.splice(0, this.tableData.length); //清空数组
			this.$axios({
				method: "post",
				url: "/api/web/cgmDevice/page", // 接口地址
				data: this.getEquManage
			}).then(res => {
				if (res.data.code === 200) {
					this.tableData = res.data.data;
					console.log(this.tableData)
					this.totalPages = res.data.pageData.totalPages;
					this.total = res.data.pageData.totalElements;
					this.loading = false;
				} else {
					this.$message({
						message: "操作失败",
						type: "error",
					});
				}
			}).catch(error => {
				console.log(error);
				//this.$message.error("请求失败");
			});
		},
	}
}
