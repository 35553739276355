import { render, staticRenderFns } from "./EquManage.vue?vue&type=template&id=63064615&scoped=true&"
import script from "./EquManage.js?vue&type=script&lang=js&"
export * from "./EquManage.js?vue&type=script&lang=js&"
import style0 from "./EquManage.vue?vue&type=style&index=0&id=63064615&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "63064615",
  null
  
)

export default component.exports